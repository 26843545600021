import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuickSell } from '../hooks/useQuickSell';
import '../css/CardMenu.css';
import CoinIcon from './CoinIcon';

const CardMenu = ({ card, onClose, updateInventoryCache }) => {
    const [quickSellPrice, setQuickSellPrice] = useState(0);
    const { quickSell, error, fetchCardFantasyTeamStatus } = useQuickSell(card._id, quickSellPrice, updateInventoryCache);
    const navigate = useNavigate();
    const [marketSellError, setMarketSellError] = useState(null);

    useEffect(() => {
        if (card) {
            setQuickSellPrice(card.tier * card.stats[0].firepower);
        }
    }, [card]);

    const handleQuickSell = async () => {
        const wasQuickSold = await quickSell();
        if (wasQuickSold) {
            onClose();
        }
    };

    const handleSell = async () => {
        const cardFantasyTeamStatus = await fetchCardFantasyTeamStatus(card._id);
        if (cardFantasyTeamStatus.status === 'ACTIVE') {
            setMarketSellError(`Can't sell card in active fantasy team`);
        }
        if (cardFantasyTeamStatus.status !== 'ACTIVE') {
            navigate(`/market/?player=${card.player.name}&rarity=${card.tier}&sellingCard=${card._id}`);
        }
    };

    return (
        <div className="card-menu">
            <div className='card-menu-container'>
                <div className='card-menu-content'>
                    <div className='col-3 card-menu-option'>
                        <div className='title-container'>
                            <h1 className='title-card-menu' onClick={handleQuickSell}>
                                Quick Sell
                            </h1>
                            <span className='subtitle-card-menu'> <CoinIcon width="18px" height="18px" /> {quickSellPrice} </span>
                        </div>
                    </div>
                    <div className='col-3 card-menu-option' onClick={handleSell}>
                        <h1 className='title-card-menu'>Sell</h1>
                    </div>
                </div>
                <button className='default-button close-button' onClick={onClose}>Close</button>
                {error && <p className='error' style={{ alignSelf: 'center' }}>{error}</p>}
                {marketSellError && <p className='error' style={{ alignSelf: 'center' }}>{marketSellError}</p>}
            </div>
        </div>
    );
}

export default CardMenu;