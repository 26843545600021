import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('Anubis');
  const [backgroundVideo, setBackgroundVideo] = useState('');

  useEffect(() => {
    // Load and apply the saved theme on page load
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      applyTheme(savedTheme);
      setTheme(savedTheme);
    } else {
      applyTheme('Anubis');
    }
  }, []);

  const applyTheme = (option) => {
    // Update the CSS variables based on the selected theme
    const root = document.documentElement;
    switch (option) {
      case 'Anubis':
        root.style.setProperty('--main', '#df8f17');
        root.style.setProperty('--maind', '#b67412');
        root.style.setProperty('--mainb', '#ffa31a');
        setBackgroundVideo('/anubis-video.mp4');
        break;
      case 'Nuke':
        root.style.setProperty('--main', '#007fff');
        root.style.setProperty('--maind', '#0059b3');
        root.style.setProperty('--mainb', '#0066cc');
        setBackgroundVideo('/nuke-video.mp4');
        break;
      case 'Ancient':
        root.style.setProperty('--main', '#00cc00');
        root.style.setProperty('--maind', '#009900');
        root.style.setProperty('--mainb', '#006600');
        setBackgroundVideo('/ancient-video.mp4');
        break;
      default:
        root.style.setProperty('--main', '#df8f17');
        root.style.setProperty('--maind', '#b67412');
        root.style.setProperty('--mainb', '#ffa31a');
        setBackgroundVideo('/anubis-video.mp4');
        break;
    }
  };

  const changeTheme = (option) => {
    // Save the selected option to localStorage
    localStorage.setItem('theme', option);

    // Apply the theme
    applyTheme(option);

    // Update the state
    setTheme(option);
  };

  return (
    <ThemeContext.Provider value={{ theme, changeTheme, backgroundVideo }}>
      {children}
    </ThemeContext.Provider>
  );
};