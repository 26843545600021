import '../css/Card.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Flag from 'react-world-flags';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useCard } from '../hooks/fetchCard';
import cardBackground from '../assets/cardBackground.png';
import UnkownPlayer from '../assets/unknownPlayer.png';

const Card = forwardRef(({ cardID, isOpening, size, market }, ref) => {
    const { fetchCard } = useCard(cardID);
    const [card, setCard] = useState(null);
    const [tierClass, setTierClass] = useState('');
    const SHOWPLAYER = process.env.REACT_APP_SHOW_PLAYERS === 'true';


    useEffect(() => {
        fetchCard(cardID).then((data) => {
            setCard(data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardID]);

    useEffect(() => {
        if (card) {
            const tierClass = getTierClass(card.tier);
            setTierClass(tierClass);
        }
    }, [card]);

    useImperativeHandle(ref, () => ({
        openCard
    }));

    const openCard = (e) => {
        if (e) {
            console.log('e:', e);
            e.preventDefault();
            $(e.currentTarget).addClass('active');
        } else {
            console.log('No event');
            $(`.card-${cardID}`).addClass('active');
        }
        // Ensure the card is scrolled into view
        const cardElement = document.querySelector(`.card-${cardID}`);
        if (cardElement) {
            cardElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    };

    if (!card) {
        return null;
    }

    const getTierClass = (tier) => {
        switch (tier) {
            case 1:
                return 'bronzeCard';
            case 2:
                return 'silverCard';
            case 3:
                return 'goldCard';
            case 4:
                return 'legendaryCard';
            default:
                console.error('Invalid tier');
                return '';
        }
    };

    const getBoostTierColor = (boost) => {
        switch (boost.tier) {
            case 1:
                return 'var(--bronze1)';
            case 2:
                return 'var(--silver1)';
            case 3:
                return 'var(--gold1)';
            default:
                console.error('Invalid boost tier');
                return '';
        }
    }

    return (
        <div className={`card-container ${size === 'small' ? 'small' : ''}`}>
            <div className={`card-${cardID} ${isOpening ? "" : "active static"} ${market ? "market" : ""} ${tierClass}`} id="card">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 267.3 427.3">
                    <clipPath id="svgPath" >
                        <path fill="#000" d="M265.3 53.9a33.3 33.3 0 0 1-17.8-5.5 32 32 0 0 1-13.7-22.9c-.2-1.1-.4-2.3-.4-3.4 0-1.3-1-1.5-1.8-1.9a163 163 0 0 0-31-11.6A257.3 257.3 0 0 0 133.7 0a254.9 254.9 0 0 0-67.1 8.7 170 170 0 0 0-31 11.6c-.8.4-1.8.6-1.8 1.9 0 1.1-.2 2.3-.4 3.4a32.4 32.4 0 0 1-13.7 22.9A33.8 33.8 0 0 1 2 53.9c-1.5.1-2.1.4-2 2v293.9c0 3.3 0 6.6.4 9.9a22 22 0 0 0 7.9 14.4c3.8 3.2 8.3 5.3 13 6.8 12.4 3.9 24.8 7.5 37.2 11.5a388.7 388.7 0 0 1 50 19.4 88.7 88.7 0 0 1 25 15.5v.1-.1c7.2-7 16.1-11.3 25-15.5a427 427 0 0 1 50-19.4l37.2-11.5c4.7-1.5 9.1-3.5 13-6.8 4.5-3.8 7.2-8.5 7.9-14.4.4-3.3.4-6.6.4-9.9V231.6 60.5v-4.6c.4-1.6-.3-1.9-1.7-2z" />
                    </clipPath>
                </svg>
                <div id="card-inner">
                    <div id="card-top">
                        <div className="info">
                            <div className="value">{card.stats[0].firepower}</div>
                            <div className="position">{card.role}</div>
                            <div className="country"><div><Flag className='flag-card' code={card.player.country.code} /></div></div>
                            <div className="club"><img src={card.team.image} style={{ transform: 'scale(0.8)' }} alt='team logo'></img></div>
                        </div>
                        <div className="image">
                            <img src={SHOWPLAYER === true ? card.image : UnkownPlayer} style={{ width: '100%', transform: 'scale(1.2)' }} alt="player" />
                        </div>
                        <div className="backfont">CS</div>
                        <img src={cardBackground} style={{ width: '100%', height: '100%', transform: 'scale(1.6)' }} alt="" />
                    </div>
                    <div id="card-bottom">
                        <div className="name">
                            {card.player.name}
                        </div>
                        <div className="stats">
                            <div>
                                <ul>
                                    <li><span>{card.stats[0].entrying}</span><span className='statText'>entry</span></li>
                                    <li><span>{card.stats[0].opening}</span><span className='statText'>open</span></li>
                                    <li><span>{card.stats[0].sniping}</span><span className='statText'>awp</span></li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li><span>{card.stats[0].trading}</span><span className='statText'>trade</span></li>
                                    <li><span>{card.stats[0].clutching}</span><span className='statText'>clutch</span></li>
                                    <li><span>{card.stats[0].utility}</span><span className='statText'>utility</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card-info">
                            {card.boosts.map((boost, index) => (
                                <div key={index} className="boost-item">
                                    <div className="boost-name-container">
                                        <div className="svg-container">
                                            <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill={getBoostTierColor(boost)} />
                                            </svg>
                                        </div>
                                        <span className="boost-name">
                                            {boost.name}:
                                        </span>
                                    </div>
                                    <span className="boost-value">
                                        +{boost.value} pts
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Card;