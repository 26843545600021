import React, { useEffect, useState } from 'react';
import '../css/CardMenu.css';
import CoinIcon from './CoinIcon';
import { useListing } from '../hooks/useListing';
import { useBank } from '../hooks/useBank';
import { useInventory } from '../hooks/fetchInventory';

const MarketMenu = ({ listing, userID, onClose, onUpdateListing, onRemoveListing }) => {
    const { removeListing, updateListing } = useListing();
    const { fetchBank, sendCoins } = useBank();
    const { addCardToInventory } = useInventory();
    const [error, setError] = useState(null);
    const [changePrice, setChangePrice] = useState(false);
    const [sellPrice, setSellPrice] = useState(listing.price);

    useEffect(() => {
        const bank = fetchBank();
    }
        , []);

    const handleChangePrice = () => {
        setChangePrice(true);
    }

    const handleUpdatePrice = async () => {
        try {
            await updateListing(listing._id, sellPrice);
            const updatedListing = { ...listing, price: sellPrice };
            onUpdateListing(updatedListing);
            setChangePrice(false);
            onClose();
        } catch (error) {
            setError(error.message);
        }
    };

    const handleRemoveListing = async () => {
        try {
            await removeListing(listing._id);
            onRemoveListing(listing._id);
            onClose();
        } catch (error) {
            setError(error.message);
        }
    }

    const handleBuy = async () => {
        try {
            // Check if user has enough coins
            const price = listing.price;
            const bank = fetchBank();
            if (bank.coins < price) {
                setError('Not enough coins');
                return;
            }

            // Remove listing
            await removeListing(listing._id);

            // Update both user's coins
            await sendCoins(listing.owner._id, price);

            // add card to user's collection
            await addCardToInventory(listing.card._id);

            // close menu and update listings
            await onRemoveListing(listing._id);
            await onClose();
        } catch (error) {
            setError(error.message);
        }
    }


    return (
        <div className="card-menu">
            <div className='card-menu-container'>

                {listing.owner._id === userID && (
                    changePrice ? (
                        <div className='card-menu-content'>
                            <div>
                                <div className='title-container' style={{ alignItems: "center", display: "flex", flexDirection: "column", width: "100%" }}>
                                    <h1 className='title-card-menu' >
                                        New Price:
                                    </h1>
                                    <div className='market-sellbar-container' style={{ width: "100%", justifyContent: "space-between" }}>
                                        <CoinIcon width='2vh' height='2vh' />
                                        <input
                                            className='market-sellbar'
                                            style={{ width: "65%", margin: "0" }}
                                            type='number'
                                            value={sellPrice}
                                            placeholder='Price'
                                            min='1'
                                            max='1000000'
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    const value = Math.max(1, Math.min(1000000, Math.floor(Number(e.target.value))));
                                                    e.target.value = value;
                                                    setSellPrice(value);
                                                } else {
                                                    e.target.value = '';
                                                    setSellPrice(null);
                                                }
                                            }}
                                        />
                                        <button className='default-button' onClick={handleUpdatePrice}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='card-menu-content'>
                            <div className='col-3 card-menu-option'>
                                <div className='title-container' onClick={handleRemoveListing}>
                                    <h1 className='title-card-menu' >
                                        Remove Listing
                                    </h1>
                                </div>
                            </div>
                            <div className='col-3 card-menu-option' onClick={handleChangePrice}>
                                <h1 className='title-card-menu'>Change Price</h1>
                            </div>
                        </div>
                    )
                )}

                {listing.owner._id !== userID && (
                    <div className='card-menu-content'>
                        <div className='col-3 card-menu-option'>
                            <div className='title-container' onClick={handleBuy}>
                                <h1 className='title-card-menu'>
                                    Buy
                                </h1>
                                <span className='subtitle-card-menu'> <CoinIcon width="18px" height="18px" /> {listing.price} </span>
                            </div>
                        </div>
                    </div>
                )}

                <button className='default-button close-button' onClick={onClose}>Close</button>
                {error && <p className='error' style={{ alignSelf: 'center' }}>{error}</p>}
            </div>
        </div>
    );
}

export default MarketMenu;