import React, { useState, useEffect, useMemo, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Inventory.css';
import Card from './Card';
import CardMenu from './CardMenu';
import { useInventory } from '../hooks/fetchInventory';
import sortAscSVG from './SortAscendingLogo';
import sortDescSVG from './SortDescendingLogo';
import { useLocation } from 'react-router-dom';

const Inventory = ({ onCardClick, fullSize = false, allowedPlayers, team, locked = false, updateInventory }) => {
    const [isCardInventory, setIsCardInventory] = useState(true);
    const [isSkinInventory, setIsSkinInventory] = useState(false);
    const [sortBy, setSortBy] = useState();
    const [sortOrder, setSortOrder] = useState('desc');
    const [cachedCards, setCachedCards] = useState([]); // State to store cached cards
    const [loading, setLoading] = useState(false);
    const { fetchInventoryCards } = useInventory();
    const [selectedCard, setSelectedCard] = useState(null);
    const [showCardMenu, setShowCardMenu] = useState(false);
    const cardCache = useRef(new Map()); // Ref to store the cached Card components
    const location = useLocation();

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            try {
                if (isCardInventory) {
                    setIsSkinInventory(false);
                    const data = await fetchInventoryCards(null, sortBy, sortOrder);
                    let filteredData = data;
                    if (allowedPlayers) {
                        filteredData = filteredData.filter(card => allowedPlayers.some(player => player._id === card.player._id));
                    }
                    // Filter out cards that are already in the team
                    if (team) {
                        filteredData = filteredData.filter(card => !team.some(teamCard => teamCard.card === card._id));
                    }
                    setCachedCards(filteredData); // Cache the data
                } else if (isSkinInventory) {
                    setIsCardInventory(false);
                    // Logic to fetch skins and update state accordingly
                    // Example:
                    // const data = await fetchInventorySkins(null, sortBy, sortOrder);
                    // setSkins(data);
                }
            } catch (error) {
                console.error("Error fetching inventory:", error);
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCardInventory, isSkinInventory, allowedPlayers, team, sortBy, sortOrder, location.state?.refresh]);

    useEffect(() => {
        // Load and apply the saved sort option on page load
        const savedSortOption = localStorage.getItem('sortOption');
        if (savedSortOption) {
            setSortBy(savedSortOption);
            const button = document.getElementById('dropdown-button');
            if (button) {
                button.textContent = `Sort by: ${savedSortOption.charAt(0).toUpperCase() + savedSortOption.slice(1)}`;
            }
        }
    }, []);

    const debounce = (func, delay) => {
        let debounceTimer;
        return function (...args) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const setSortOption = debounce((option) => {
        // Save the selected option to localStorage
        localStorage.setItem('sortOption', option);

        // Update the dropdown button text
        const button = document.getElementById('dropdown-button');
        if (button) {
            button.textContent = `Sort by: ${option.charAt(0).toUpperCase() + option.slice(1)}`;
        }

        // Update the state
        setSortBy(option);
    }, 300);

    const sortedCards = useMemo(() => {
        if (!cachedCards) return [];
        const sorted = [...cachedCards];
        sorted.sort((a, b) => {
            let primaryComparison = 0;
            if (sortBy === 'name') {
                primaryComparison = sortOrder === 'asc' ? a.player.name.localeCompare(b.player.name) : b.player.name.localeCompare(a.player.name);
            } else if (sortBy === 'tier') {
                primaryComparison = sortOrder === 'asc' ? a.tier - b.tier : b.tier - a.tier;
            } else if (sortBy === 'acquired') {
                primaryComparison = sortOrder === 'asc' ? new Date(a.acquired) - new Date(b.acquired) : new Date(b.acquired) - new Date(a.acquired);
            } else if (sortBy === 'team') {
                primaryComparison = sortOrder === 'asc' ? a.team.name.localeCompare(b.team.name) : b.team.name.localeCompare(a.team.name);
            } else if (sortBy === 'rating') {
                primaryComparison = sortOrder === 'asc' ? a.stats[0].firepower - b.stats[0].firepower : b.stats[0].firepower - a.stats[0].firepower;
            } else {
                primaryComparison = sortOrder === 'asc' ? a.stats[0][sortBy] - b.stats[0][sortBy] : b.stats[0][sortBy] - a.stats[0][sortBy];
            }
    
            // Secondary comparison by name
            if (primaryComparison === 0) {
                return a.player.name.localeCompare(b.player.name);
            }
    
            return primaryComparison;
        });
        return sorted;
    }, [cachedCards, sortBy, sortOrder]);

    const openCardMenu = (card) => {
        setSelectedCard(card);
        setShowCardMenu(true);
    };

    const closeCardMenu = () => {
        setSelectedCard(null);
        setShowCardMenu(false);
    };

    const updateInventoryCache = (cardID) => {
        setCachedCards(prevCards => prevCards.filter(card => card._id !== cardID));
        cardCache.current.delete(cardID); // Remove from cache
    };

    // Function to add a new listing to the inventory
    const addNewListingToInventory = (newListing) => {
        setCachedCards(prevCards => [newListing, ...prevCards]);
        const cacheKey = `${newListing._id}-${onCardClick}`;
        cardCache.current.set(cacheKey, (
            <div key={newListing._id} className='col-3'>
                <div className='cardDisplay-container' onClick={() => onCardClick ? onCardClick(newListing) : openCardMenu(newListing)}>
                    <Card cardID={newListing.card._id} />
                </div>
            </div>
        ));
    };

    return (
        <div className={`app-container ${fullSize ? '' : 'col-10 offset-1'}`} style={fullSize ? { height: 'auto', minHeight: '80%' } : {}}>
            <div className='inventoryTitle-container'>
                <div className=' inventory-box col-10'>
                    <div className='col-auto'>
                        <h1 className='title-inventory'>Inventory</h1>
                    </div>
                    <div className="text-inventory col-auto">
                        <p onClick={() => setIsCardInventory(true)}>Cards</p>
                    </div>
                    <div className="text-inventory col-auto">
                        <p onClick={() => setIsSkinInventory(true)}>Skins</p>
                    </div>
                </div>
                <div className='col-2 inventory-box-filter'>
                    <div className="dropdown">
                        <button id="dropdown-button" className="dropdown-button">Select Sort Option</button>
                        <div id="dropdown-content" className="dropdown-content">
                            <p onClick={() => setSortOption('acquired')}>Acquired</p>
                            <p onClick={() => setSortOption('tier')}>Rarity</p>
                            <p onClick={() => setSortOption('rating')}>Rating</p>
                            <p onClick={() => setSortOption('name')}>Name</p>
                            <p onClick={() => setSortOption('team')}>Team</p>
                            <p onClick={() => setSortOption('entrying')}>Entrying</p>
                            <p onClick={() => setSortOption('opening')}>Opening</p>
                            <p onClick={() => setSortOption('sniping')}>Sniping</p>
                            <p onClick={() => setSortOption('trading')}>Trading</p>
                            <p onClick={() => setSortOption('clutching')}>Clutching</p>
                            <p onClick={() => setSortOption('utility')}>Utility</p>
                        </div>
                    </div>
                    <div className='sort-container'>
                        <div className='sort-svg' onClick={toggleSortOrder}
                            dangerouslySetInnerHTML={{ __html: sortOrder === 'desc' ? sortDescSVG : sortAscSVG }}
                        />
                    </div>
                </div>
            </div>

            {loading ? (
                <div className='col-10 offset-1 inventory-container'>
                    <div className='loader'></div>
                </div>
            ) : (
                isCardInventory && (
                    <div className={`${fullSize ? 'inventory-container-fullSize' : 'inventory-container'}`}>
                        {locked && (
                            <div className='inventory-locked'>
                                <p className='inventory-locked-text'>Inventory is locked</p>
                            </div>
                        )}
                        {sortedCards.length > 0 && (
                            sortedCards.map((card, index) => {
                                // Check if the Card component is already cached
                                const cacheKey = `${card._id}-${onCardClick}`;
                                if (!cardCache.current.has(cacheKey)) {
                                    // Cache the Card component with onCardClick as part of the key
                                    cardCache.current.set(cacheKey, (
                                        <div key={index} className='col-3'>
                                            <div className='cardDisplay-container' onClick={() => onCardClick ? onCardClick(card) : openCardMenu(card)}>
                                                <Card cardID={card._id} />
                                            </div>
                                        </div>
                                    ));
                                }
                                // Return the cached Card component
                                return cardCache.current.get(cacheKey);
                            })
                        )}
                    </div>
                )
            )}

            {/* 
      isSkinInventory && (
        <div className='inventory-container row col-10 offset-1'>
          {skins.length > 0 ? (
            skins.map((skin, index) => (
              <div key={index} className='col-3'>
                <div className='cardDisplay-container'>
                  <Skin skinID={skin._id} />
                </div>
              </div>
            ))
          ) : (
            <h2>Obtain skins by opening cases!</h2>
          )}
        </div>
      )
      */}
            {showCardMenu && selectedCard && (
                <div className='popup-overlay'>
                    <CardMenu card={selectedCard} onClose={closeCardMenu} updateInventoryCache={updateInventoryCache} />
                </div>
            )}
        </div>
    );
};

export default Inventory;