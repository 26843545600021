import React, { useEffect, useState } from 'react';
import '../css/Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useUser } from '../hooks/useUser';
import CoinIcon from '../components/CoinIcon';

function Header() {
    const [sticky, setSticky] = useState(false);
    const { user, loading, error } = useUser();
    const [coins, setCoins] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 0);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (user && user.bank) {
            setCoins(user.bank.coins);
        }
    }, [user]);

    useEffect(() => {
        const ws = new WebSocket(`wss://api.csultimatefantasy.com/ws`);

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'UPDATE_COINS' && data.userID === user?._id) {
                setCoins(data.coins);
            }
        };

        return () => {
            ws.close();
        };
    }, [user]);

    return (
        <header className={`${sticky ? "sticky" : ""}`}>
            <nav className="navbar col-10 offset-1">
                <div className="navbar-links">
                    <div className="close col-auto" style={{ paddingRight: "0" }}>
                        <a href="/">Home</a>
                    </div>
                    <div className="col-auto close">
                        <a href="/packs">Packs</a>
                    </div>
                    <div className="col-auto close">
                        <a href="/inventory">Inventory</a>
                    </div>
                    <div className="col-auto close">
                        <a href="/events">Events</a>
                    </div>
                    <div className="col-auto close">
                        <a href="/market">Market</a>
                    </div>
                    <div className="col-auto close">
                        <a href="/leaderboard">Leaderboard</a>
                    </div>
                </div>
                <div className="navbar-user-info">
                    <div>
                        <p><CoinIcon width="18px" height="18px" /> {loading ? '' : error ? 'Error' : coins}</p>
                    </div>
                    <div>
                        <a href="/profile">{loading ? '' : error ? 'Error' : user?.username || 'Profile'}</a>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;