import { useState } from "react";
import { useAuthContext } from "./useAuthContext";

export const useSignUp = () => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);
    const { dispatch } = useAuthContext();

    const signUp = async (username, password, passwordConfirm) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    password,
                    passwordConfirm
                }),
                credentials: 'include' // Include cookies in the request
            });
            
            if (response.ok) {
                const json = await response.json();
                //update auth context
                dispatch({
                    type: 'LOGIN',
                    payload: { json }
                });
                console.log('User created successfully');
            } else {
                const data = await response.json();
                throw new Error(data.message);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }
    return { error, loading, signUp };
}