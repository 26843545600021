import '../css/EventsFrontpage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Flag from 'react-world-flags'
import { useEvents } from '../../src/hooks/useEvents';
import { useNavigate } from 'react-router-dom';

const EventsFrontpage = () => {
    const { events, error, loading } = useEvents();
    const navigate = useNavigate();

    const formatDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp);
        return date.toLocaleDateString('da-DK', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    // Calculate the date one month from now
    const futureDate = new Date();
    futureDate.setMonth(futureDate.getMonth() + 1);

    // Filter events to include only those starting within the next 14 days and not yet ended
    const filteredEvents = events.filter(event => {
        const startDate = new Date(event.startDate).getTime();
        const endDate = new Date(event.endDate).getTime();
        return startDate <= futureDate && endDate >= Date.now();
    });

    return (
        <div className='eventsFrontpage-container'>
            <h1 className='title-events'>Featured Events</h1>
            {loading &&
                <div className='eventsBox'>
                    <div className='loader' />
                </div>}
            {filteredEvents.length > 0 && (
                <div className='eventsBox'>
                    {filteredEvents.map((event, index) => (
                        <div key={index} className='event-wrapper'>
                            <div className='events' onClick={() => navigate(`/event/${event.hltv_id}`)}>
                                <div className='event-content'>
                                    {event.image && <img className='event-image' src={event.image} alt='event' />}
                                    <div className='event-info'>
                                        {event.name && <p className='event-name'>{event.name}</p>}
                                        <div className='event-details'>
                                            {event.startDate && <p className='event-date'>{formatDate(event.startDate)} - {formatDate(event.endDate)}</p>}
                                            {event.location && <p className='event-location'>Location: <Flag className='flag' code={event.location.code} /> {event.location.name}</p>}
                                            {event.prizePool && <p className='event-prizepool'>Prize pool: {event.prizePool}</p>}
                                            <b className='moreinfo' onClick={(e) => { e.stopPropagation(); window.open(`https://www.hltv.org/events/${event.hltv_id}/${event.name}`, '_blank'); }}>More stats</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {error && <p className='error'>{error}</p>}
        </div>
    );
}

export default EventsFrontpage;