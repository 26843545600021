import { useState, useEffect } from 'react';
import { fetchWithAuth } from './authHelpers'; // Assuming you have a similar helper

export const useFantasyTeams = (userId, eventId, seasonId) => {
  const [fantasyTeams, setFantasyTeams] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFantasyTeams = async () => {
      setLoading(true);
      setError(null);

      try {
        let url = '';
        if (eventId) {
          url = `${process.env.REACT_APP_BACKEND_URL}/fantasyteam/user/${userId}/event/${eventId}`;
        } else if (seasonId) {
          url = `${process.env.REACT_APP_BACKEND_URL}/fantasyteam/user/${userId}/season/${seasonId}`;
        } else {
          url = `${process.env.REACT_APP_BACKEND_URL}/fantasyteam/user/${userId}`;
        }


        const response = await fetchWithAuth(url, 'GET');

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to fetch fantasy team');
        }

        const data = await response.json();
        setFantasyTeams(data);
      } catch (err) {
        console.error(`Error fetching fantasy team:`, err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchFantasyTeams();
    }
  }, [userId, eventId, seasonId]);

  return { fantasyTeams, loading, error };
};

export default useFantasyTeams;