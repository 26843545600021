import '../css/FrontPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import NewsSide from './NewsSide';
import EventsFrontPage from './EventsFrontpage';


const FrontPage = () => {
    return (
        <div className='fronpage-container col-10 offset-1'>
            <div className='col-3 newsSide-container'>
                <NewsSide />
            </div>
            <div className='col-9'>
                <div className='col-11 offset-1'>
                    <div>
                        <EventsFrontPage />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default FrontPage;