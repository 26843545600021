import React, { useContext, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Profile.css';
import { useLogOut } from '../hooks/useLogOut';
import { useUser } from '../hooks/useUser';
import { useUserStats } from '../hooks/useUserStats';
import { useFantasyTeams } from '../hooks/useFantasyTeams';
import { ThemeContext } from '../context/ThemeContext';

const Profile = () => {
  const { user, loading, error } = useUser();
  const { userStats, loadingStats, errorStats } = useUserStats();
  const { logOut } = useLogOut();
  const { fantasyTeams, loadingTeams, errorTeams } = useFantasyTeams(user?._id);
  const { theme, changeTheme } = useContext(ThemeContext);
  const [eventsParticipated, setEventsParticipated] = useState(0);
  const [averagePlacement, setAveragePlacement] = useState(0);
  const [eventsWon, setEventsWon] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    if (fantasyTeams) {
      setEventsParticipated(fantasyTeams.length);
      let totalPlacement = 0;
      let wonEvents = 0;
      let points = 0;

      for (const team of fantasyTeams) {
        if (team.position === 1) {
          wonEvents += 1;
        }
        points += team.points;
        totalPlacement += team.placement;
      }

      setEventsWon(wonEvents);
      setTotalPoints(points);

      const avgPlacement = fantasyTeams.length && totalPlacement > 0 ? totalPlacement / fantasyTeams.length : 0;
      setAveragePlacement(avgPlacement);
    }
  }, [fantasyTeams]);

  useEffect(() => {
    // Load and apply the saved theme on page load
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      const button = document.getElementById('dropdown-button');
      if (button) {
        button.textContent = `Theme: ${savedTheme.charAt(0).toUpperCase() + savedTheme.slice(1)}`;
      }
    }
  }, []);

  const handleLogOut = async (e) => {
    logOut();
  }

  const formatDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp);
    return date.toLocaleDateString('da-DK', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const updateTheme = (theme) => {
    changeTheme(theme);
    const button = document.getElementById('dropdown-button');
    if (button) {
      button.textContent = `Theme: ${theme.charAt(0).toUpperCase() + theme.slice(1)}`;
    }
  }

  return (
    <div className='app-container col-10 offset-1 '>

      <div className='profile-title-container'>
        <div className='col-10'>
          <h1 className='title-profile'>Profile</h1>
        </div>
        <div className='col-2 profile-options'>
          <div className='col-2 inventory-box-filter'>
            <div className="dropdown">
              <button id="dropdown-button" className="dropdown-button">Select Theme</button>
              <div id="dropdown-content" className="dropdown-content">
                <p onClick={() => updateTheme('Anubis')}>Anubis</p>
                <p onClick={() => updateTheme('Nuke')}>Nuke</p>
                <p onClick={() => updateTheme('Ancient')}>Ancient</p>
              </div>
            </div>
          </div>
          <button className='logout-button default-button' onClick={handleLogOut}>
            Log Out
          </button>
        </div>
      </div>

      <div className='profile-information-container'>
        <h6>Username: {user?.username}</h6>
        <h6>Email: {user?.email}</h6>
        <h6>Date Joined: {user && formatDate(user.dateJoined)}</h6>
        {/* 
        <div>
          <button className='default-button' style={{ marginRight: "10px" }} onClick={handleLogOut}>
            Change Password
          </button>
          <button className='default-button' onClick={handleLogOut}>
            Change Email
          </button>
        </div>
        */}
      </div>

      <div className='profile-information-container'>
        <h3 className='title-profile'>Stats</h3>
      </div>

      <div className='profile-information-container'>
        <div className='profile-information-section'>
          <h6>Packs Opened: {userStats?.packsOpened ?? 0} </h6>
          <h6>Bronze Opened: {userStats?.bronzeOpened ?? 0} </h6>
          <h6>Silver Opened: {userStats?.silverOpened ?? 0} </h6>
          <h6>Gold Opened: {userStats?.goldOpened ?? 0} </h6>
          <h6>Legendaries Opened: {userStats?.legendariesOpened ?? 0} </h6>
        </div>

        <div className='profile-information-section'>
          <h6>Events Participated: {eventsParticipated ?? 0}</h6>
          <h6>Average Placement: {averagePlacement ?? 0}</h6>
          <h6>Events Won: {eventsWon ?? 0}</h6>
          <h6>Total Points: {totalPoints.toFixed(2) ?? 0}</h6>
        </div>
      </div>
    </div>
  );
}

export default Profile;