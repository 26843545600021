import { useState, useEffect } from 'react';
import { fetchWithAuth } from './authHelpers';

const useEvent = (event_id) => {
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fantasyTeam, setFantasyTeam] = useState(null);
  const [saveError, setSaveError] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventResponse = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/events/hltv/${event_id}`, 'GET');
        const eventData = await eventResponse.json();
        setEvent(eventData);

        const fantasyTeamResponse = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/fantasyteam/user/current/event/${eventData._id}`, 'GET');
        if (fantasyTeamResponse.ok) {
          const fantasyTeamData = await fantasyTeamResponse.json();
          setFantasyTeam(fantasyTeamData[0]);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [event_id]);

  const handleSaveTeam = async (event_id, team) => {
    if (team.length < 5) {
      setSaveError('Please select 5 players');
      return;
    }

    try {
      let response;

      if (fantasyTeam) {
        response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/fantasyteam/${fantasyTeam._id}`, 'PUT', {
          cards: team
        });
      } else {
        response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/fantasyteam`, 'POST', {
          eventHltvID: event_id,
          cards: team
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to save fantasy team');
      }

      const updatedFantasyTeam = await response.json();
      setFantasyTeam(updatedFantasyTeam);
      await upsertFantasyToEvent(updatedFantasyTeam);

      // Add fantasy team to each new card
      const oldTeam = fantasyTeam ? fantasyTeam.cards : [];
      const cardsToAdd = team.filter(cardId => !oldTeam.includes(cardId));
      for (const card of cardsToAdd) {
        await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/cards/${card.card}/addFantasyTeam`, 'PATCH', { fantasyTeam: updatedFantasyTeam._id });
      }

      // Remove fantasy team from cards not in the team
      if (fantasyTeam) {
        const cardsToRemove = oldTeam.filter(cardId => !team.includes(cardId));
        for (const card of cardsToRemove) {
          await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/cards/${card.card}/removeFantasyTeam`, 'PATCH', { fantasyTeam: updatedFantasyTeam._id });
        }
      }

      setSaveError(null);
    } catch (err) {
      setSaveError(err.message);
      console.error(err.message);
    }
  };

  const upsertFantasyToEvent = async (fantasyTeam) => {
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/events/${event._id}/upsertfantasy`, 'POST', { fantasyTeam });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to add fantasy team to event');
      }
    } catch (err) {
      setSaveError(err.message);
      console.error(err.message);
    }
  };

  const lockEvent = async (event_id) => {
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/events/${event_id}/lock`, 'PUT');

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to lock event');
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  return { event, loading, error, fantasyTeam, saveError, setFantasyTeam, handleSaveTeam, lockEvent };
};

export default useEvent;