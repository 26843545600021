import React, { useEffect, useState, useMemo } from 'react';
import Card from './Card';
import FantasyCardStats from './FantasyCardStats';
import useFantasyTeams from '../hooks/useFantasyTeams';
import useTeamImages from '../hooks/useTeamImages';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/FantasyTeamView.css';

const FantasyTeamView = ({ userID, event, season, onClose }) => {
    const { fantasyTeams, loading, error } = useFantasyTeams(userID, event._id);
    const [team, setTeam] = useState([]);
    const [showOverlay, setShowOverlay] = useState(false); // State for overlay visibility
    const [overlayContent, setOverlayContent] = useState(null); // State for overlay content

    useEffect(() => {
        if (fantasyTeams) {
            // Map each card to its correct position
            const updatedTeam = (fantasyTeams[0].cards || []).map(card => ({
                ...card,
                position: card.position
            }));
            setTeam(updatedTeam);
        }
    }, [fantasyTeams]);

    const matches = useMemo(() => team.map(card => card.matches).flat(), [team]);

    const images = useTeamImages(matches);

    const getPointsStyle = (point) => {
        return {
            color: point < 0 ? 'var(--lightred)' : point > 0 ? 'var(--lightgreen)' : 'inherit',
        };
    };

    const cardStats = (fantasyCard) => {
        setOverlayContent(fantasyCard); // Set the card data to be displayed in the overlay
        setShowOverlay(true); // Show the overlay
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="overlay" onClick={handleOverlayClick}>
            <div className="fantasyTeamView-content" onClick={(e) => e.stopPropagation()}>
                <button className="overlay-close" onClick={onClose}>X</button>
                <div className="event-fantasy-team-container overlay-content-center">
                    {loading && <div className='loader'></div>}
                    {error && <p className='error'>{error}</p>}
                    {!loading && !error && team.length === 0 && <p>No team data available.</p>}
                    {team.length > 0 && (
                        <div className="cards-container">
                            {Array.from({ length: 5 }).map((_, index) => {
                                const card = team.find(c => c.position === index + 1);
                                return (
                                    <div key={index} className='event-fantasy-player'>
                                        {card ? (
                                            <div onClick={() => cardStats(card)}>
                                                <Card cardID={card.card} />
                                                <p className='card-points'>
                                                    <span style={getPointsStyle(card.points)}>{Math.round(card.points)}</span> Points (
                                                    <span style={getPointsStyle(card.lastPoints)}>
                                                        {card.lastPoints && Math.round(card.lastPoints)}
                                                    </span> Points)
                                                </p>
                                            </div>
                                        ) : (
                                            <img className='fantasy-player-image' src={'https://static.hltv.org/images/playerprofile/bodyshot/unknown.png'} alt='player' />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {fantasyTeams && (
                        <p className="points" style={{ width: 'auto', height: 'auto' }}>
                            <span style={getPointsStyle(fantasyTeams[0].points)}>{fantasyTeams[0].points.toFixed(2)}</span> Points
                        </p>
                    )}
                </div>
            </div>
            {/* Render the overlay */}
            {showOverlay && (
                <FantasyCardStats fantasyCard={overlayContent} onClose={() => setShowOverlay(false)} teamImages={images} />
            )}
        </div>
    );
};

export default FantasyTeamView;