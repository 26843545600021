import { fetchWithAuth } from "./authHelpers";

const cache = {};

export const useInventory = () => {

    const fetchInventoryCards = async (filters, sortBy, sortOrder, allowedPlayers) => {

        // Get the current user ID
        let userID;
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/users/current`, 'GET');
            const user = await response.json();
            const userID = await user.id;
        } catch (error) {
            console.error('Error fetching user ID:', error);
        }

        const cacheKey = `${userID}`;

        if (cache[cacheKey]) {
            return cache[cacheKey];
        }

        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/inventory/getCards/players`, 'POST');
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            cache[cacheKey] = data; // Cache the response
            return data;
        } catch (error) {
            console.error('Error fetching inventory details:', error);
        }
    };

    const addCardToInventory = async (cardID) => {
        if (!cardID) {
            console.log('Invalid data');
            return;
        }

        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/inventory/addCardToCurrentUser`, 'POST', { cardID });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error adding card to inventory:', error);
        }
    }

    return { fetchInventoryCards, addCardToInventory };
};