import '../css/NewsSide.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { useHltvNews } from '../../src/hooks/useHltvNews';

const NewsSide = () => {
    const { news, loading, error } = useHltvNews(15);

    return (
        <div>
            <h1 className='title-news'>News</h1>
            {loading &&
                <div className='newsBox'>
                    <div className='loader' />
                </div>
            }
            {news.length > 0 &&
                <div className='newsBox'>
                    {error && <div className='error'>Error fetching news</div>}
                    {news.map((item, index) => (
                        <div className='news' key={index} onClick={() => window.open(`https://www.hltv.org${item.link}`, '_blank')}>
                            <p className='news-name' target="_blank" rel="noopener noreferrer">{item.title}</p>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}

export default NewsSide;