import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Card from './Card';
import CoinIcon from './CoinIcon';
import MarketMenu from './MarketMenu';
import { useListing } from '../hooks/useListing';
import { usePlayer } from '../hooks/usePlayer';
import { useTeam } from '../hooks/useTeam';
import { useBoost } from '../hooks/useBoost';
import { useUser } from '../hooks/useUser';
import { useMarket } from '../hooks/useMarket';
import SearchSuggestions from './SearchSuggestions';
import Dropdown from './Dropdown';
import sortAscSVG from './SortAscendingLogo';
import sortDescSVG from './SortDescendingLogo';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Market.css';

const Market = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const sellingCard = queryParams.get('sellingCard');
    const sellingCardPlayer = queryParams.get('player');
    const sellingCardTier = queryParams.get('rarity');
    const [sellPrice, setSellPrice] = useState(0);
    const { listings, loading, error, fetchListings } = useListing();
    const { playerNames, loading: playersLoading, error: playersError } = usePlayer();
    const { teamNames, loading: teamsLoading, error: teamsError } = useTeam();
    const { boostNames, loading: boostsLoading, error: boostsError } = useBoost();
    const { user } = useUser();
    const { addListing, error: marketError } = useMarket(sellingCard, sellPrice);
    const [currentUserId, setCurrentUserId] = useState(null);
    const listingCache = useRef(new Map());
    const [filteredListings, setFilteredListings] = useState();
    const [view, setView] = useState('cards');
    const [sortBy, setSortBy] = useState();
    const [sortOrder, setSortOrder] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 12;
    const [searchPlayer, setSearchPlayer] = useState('');
    const [searchTeam, setSearchTeam] = useState('');
    const [selectedTiers, setSelectedTiers] = useState([]);
    const [selectedBoosts, setSelectedBoosts] = useState([null, null, null]);
    const [selectedBoostTiers, setSelectedBoostTiers] = useState([null, null, null]);
    const [showMarketMenu, setShowMarketMenu] = useState(false);
    const [selectedListing, setSelectedListing] = useState(null);

    useEffect(() => {
        fetchListings();
    }, []);

    useEffect(() => {
        if (user) {
            setCurrentUserId(user?._id);
        }
    }, [user]);

    useEffect(() => {
        console.log('sellingCardPlayer:', sellingCardPlayer);
        setSearchPlayer(sellingCardPlayer || '');
        setSelectedTiers(sellingCardTier ? [Number(sellingCardTier)] : []);
    }, [sellingCardPlayer, sellingCardTier]);

    useEffect(() => {
        if (listings !== null) {
            submitFilters();
        }
    }, [listings]);

    useEffect(() => {
        const fetchData = async () => {
            if (view === 'cards') {
                setFilteredListings(listings);
                setCurrentPage(1);
            }
            if (view === 'listings') {
                setFilteredListings(listings.filter(listing => listing.owner._id === currentUserId));
                setCurrentPage(1);
            }
            if (view === 'skins') {
                // fetch skins
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    const debounce = (func, delay) => {
        let debounceTimer;
        return function (...args) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const setSortOption = debounce((option) => {
        // Save the selected option to localStorage
        localStorage.setItem('marketSortOption', option);

        // Update the dropdown button text
        const button = document.getElementById('dropdown-button');
        if (button) {
            button.textContent = `Sort by: ${option.charAt(0).toUpperCase() + option.slice(1)}`;
        }

        // Update the state
        setSortBy(option);
    }, 300);

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
    };

    const sortedListings = useMemo(() => {
        const dataToSort = filteredListings;
        if (!dataToSort) return [];
        const sorted = [...dataToSort];

        const compareBoosts = (a, b, boostIndex) => {
            if (selectedBoosts[boostIndex]) {
                const boostA = a.card.boosts.find(boost => boost.boost === selectedBoosts[boostIndex]._id);
                const boostB = b.card.boosts.find(boost => boost.boost === selectedBoosts[boostIndex]._id);
                if (boostA && boostB) {
                    return sortOrder === 'asc' ? boostA.value - boostB.value : boostB.value - boostA.value;
                } else if (boostA) {
                    return -1;
                } else if (boostB) {
                    return 1;
                }
            }
            return 0;
        };

        if (sortBy === 'listed') {
            console.log('Sorting by listed');
            // Create a copy of the dataToSort array before reversing
            const sortedCopy = [...dataToSort];
            if (sortOrder === 'asc') {
                console.log('Sorting list');
                return sortedCopy;
            } else {
                console.log('Reversing list');
                return sortedCopy.reverse();
            }
        }

        sorted.sort((a, b) => {
            let primaryComparison = 0;

            if (sortBy === 'player') {
                primaryComparison = sortOrder === 'asc' ? a.card.player.name.localeCompare(b.card.player.name) : b.card.player.name.localeCompare(a.card.player.name);
            } else if (sortBy === 'price') {
                primaryComparison = sortOrder === 'asc' ? a.price - b.price : b.price - a.price;
            } else if (sortBy === 'rarity') {
                primaryComparison = sortOrder === 'asc' ? a.card.tier - b.card.tier : b.card.tier - a.card.tier;
            } else if (sortBy === 'boost 1') {
                primaryComparison = compareBoosts(a, b, 0);
            } else if (sortBy === 'boost 2') {
                primaryComparison = compareBoosts(a, b, 1);
            } else if (sortBy === 'boost 3') {
                primaryComparison = compareBoosts(a, b, 2);
            }

            return primaryComparison;
        });

        return sorted;
    }, [filteredListings, listings, sortBy, sortOrder]);

    const onListingClick = (listing) => {
        setSelectedListing(listing);
        setShowMarketMenu(true);
        console.log('Listing clicked:', listing);
    };

    const handleBoostSelect = (item, slot) => {
        const newSelectedBoosts = [...selectedBoosts];
        newSelectedBoosts[slot] = item;
        setSelectedBoosts(newSelectedBoosts);
    };

    const handleBoostTierSelect = (item, slot) => {
        const newSelectedBoostTiers = [...selectedBoostTiers];
        newSelectedBoostTiers[slot] = item;
        setSelectedBoostTiers(newSelectedBoostTiers);
        console.log('Boost tier selected:', item, slot);
        console.log('Selected boost tiers:', selectedBoostTiers);
    };

    const handleBoostFilterReset = (slot) => {
        const newSelectedBoosts = [...selectedBoosts];
        const newSelectedBoostTiers = [...selectedBoostTiers];
        newSelectedBoosts[slot] = null;
        newSelectedBoostTiers[slot] = null;
        setSelectedBoosts(newSelectedBoosts);
        setSelectedBoostTiers(newSelectedBoostTiers);
    }

    // Calculate the current entries to display
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = sortedListings.slice(indexOfFirstEntry, indexOfLastEntry);

    // Calculate total pages
    const totalPages = Math.ceil(sortedListings.length / entriesPerPage);

    const submitFilters = () => {
        if (!listings) {
            return;
        }

        setCurrentPage(1);

        let filteredData = listings;

        if (searchPlayer) {
            filteredData = filteredData.filter(listing =>
                listing.card.player.name.toLowerCase().includes(searchPlayer.toLowerCase())
            );
        }

        if (searchTeam) {
            filteredData = filteredData.filter(listing =>
                listing.card.team.name.toLowerCase().includes(searchTeam.toLowerCase())
            );
        }

        if (selectedTiers.length > 0) {
            filteredData = filteredData.filter(listing =>
                selectedTiers.includes(listing.card.tier)
            );
        }

        //filter out listings that don't have the selected boosts
        for (let i = 0; i < 3; i++) {
            if (selectedBoosts[i]) {
                if (selectedBoostTiers[i]) {
                    filteredData = filteredData.filter(listing =>
                        listing.card.boosts.some(boost =>
                            boost.name === selectedBoosts[i].name &&
                            boost.tier === selectedBoostTiers[i].tier
                        )
                    );
                } else {
                    filteredData = filteredData.filter(listing =>
                        listing.card.boosts.some(boost =>
                            boost.name === selectedBoosts[i].name
                        )
                    );
                }
            }
        }

        setFilteredListings(filteredData);
        console.log('Filtered data:', filteredData);
        console.log('Filtered data:', filteredData);
    };

    const handleCreateListing = async () => {
        console.log('Creating listing for card:', sellingCard, 'at price:', sellPrice);
        if (!sellingCard || !sellPrice || sellPrice < 1) {
            return;
        }
        const wasListed = await addListing();
        if (wasListed) {
            //go back to inventory and refresh
            navigate('/inventory');
            window.location.reload();
        }
    };

    const handleUpdateListing = (updatedListing) => {
        // Update the listingCache
        listingCache.current.forEach((value, key) => {
            if (key.includes(updatedListing._id)) {
                listingCache.current.set(key, (
                    <div key={key} className='col-3'>
                        <div className={`cardDisplay-container market-listing ${updatedListing.owner._id === currentUserId && 'highlight'}`} onClick={() => onListingClick(updatedListing)}>
                            <p className={`market-playername`} >{updatedListing.card.player.name}</p>
                            <Card cardID={updatedListing.card._id} market />
                            <div className='market-listing-price'>
                                <CoinIcon width='20px' height='20px' />
                                <p>{updatedListing.price}</p>
                            </div>
                        </div>
                    </div>
                ));
            }
        });

        // Update the filteredListings state
        setFilteredListings(prevListings => prevListings.map(listing =>
            listing._id === updatedListing._id ? updatedListing : listing
        ));
    };


    const handleRemoveListing = (listingId) => {
        // Remove the listing from the cache
        listingCache.current.forEach((value, key) => {
            if (key.includes(listingId)) {
                listingCache.current.delete(key);
            }
        });

        // Update the listings and filteredListings state
        setFilteredListings(prevListings => prevListings.filter(listing => listing._id !== listingId));
    };

    const Checkboxes = () => {
        const handleCheckboxChange = (tier) => {
            setSelectedTiers(prev =>
                prev.includes(tier) ? prev.filter(t => t !== tier) : [...prev, tier]
            );
        };

        return (
            <div className="market-checkbox-group">
                <fieldset className="checkbox-group">
                    <div className="checkbox-row">
                        <div className="checkbox">
                            <label className="checkbox-wrapper">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    checked={selectedTiers.includes(1)}
                                    onChange={() => handleCheckboxChange(1)}
                                />
                                <span className="checkbox-tile">
                                    <span className="checkbox-label">Bronze</span>
                                </span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label className="checkbox-wrapper">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    checked={selectedTiers.includes(2)}
                                    onChange={() => handleCheckboxChange(2)}
                                />
                                <span className="checkbox-tile">
                                    <span className="checkbox-label">Silver</span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="checkbox-row">
                        <div className="checkbox">
                            <label className="checkbox-wrapper">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    checked={selectedTiers.includes(3)}
                                    onChange={() => handleCheckboxChange(3)}
                                />
                                <span className="checkbox-tile">
                                    <span className="checkbox-label">Gold</span>
                                </span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label className="checkbox-wrapper">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    checked={selectedTiers.includes(4)}
                                    onChange={() => handleCheckboxChange(4)}
                                />
                                <span className="checkbox-tile">
                                    <span className="checkbox-label">Legendary</span>
                                </span>
                            </label>
                        </div>
                    </div>
                </fieldset>
            </div>
        );
    };

    useEffect(() => {
        if (totalPages > 0 && currentPage > totalPages) {
            setCurrentPage(totalPages);
        } else if (currentPage < 1) {
            setCurrentPage(1);
        }
    }, [currentPage, totalPages]);

    return (
        <div className="app-container col-10 offset-1" style={{ minHeight: '80%' }}>
            <div className='inventoryTitle-container'>
                <div className='col-10 inventory-box'>
                    <div className='col-auto'>
                        <h1 className='title-inventory'>Market</h1>
                    </div>
                    <div className="text-inventory col-auto">
                        <p onClick={() => setView('listings')}>Selling</p>
                    </div>
                    <div className="text-inventory col-auto">
                        <p onClick={() => setView('cards')}>Cards</p>
                    </div>
                    <div className="text-inventory col-auto">
                        <p onClick={() => setView('skins')}>Skins</p>
                    </div>
                </div>
                <div className='col-2 inventory-box-filter'>
                    <div className="dropdown">
                        <button id="dropdown-button" className="dropdown-button">Select Sort Option</button>
                        <div id="dropdown-content" className="dropdown-content">
                            <p onClick={() => setSortOption('listed')}>Listed</p>
                            <p onClick={() => setSortOption('player')}>Player</p>
                            <p onClick={() => setSortOption('price')}>Price</p>
                            <p onClick={() => setSortOption('rarity')}>Rarity</p>
                            {selectedBoosts[0] && <p onClick={() => setSortOption('boost 1')}>Boost 1</p>}
                            {selectedBoosts[1] && <p onClick={() => setSortOption('boost 2')}>Boost 2</p>}
                            {selectedBoosts[2] && <p onClick={() => setSortOption('boost 3')}>Boost 3</p>}
                        </div>
                    </div>
                    <div className='sort-container'>
                        <div className='sort-svg' onClick={toggleSortOrder}
                            dangerouslySetInnerHTML={{ __html: sortOrder === 'desc' ? sortDescSVG : sortAscSVG }}
                        />
                    </div>
                </div>
            </div>
            {loading ? (
                <div className='col-10 offset-1 inventory-container'>
                    <div className='loader'></div>
                </div>
            ) : (
                (view === 'cards' || view === 'listings') && (
                    <div className="market-container">
                        {view === 'cards' &&
                            <div className="market-filter col-2">
                                <h1 className='title-inventory'>Filter</h1>
                                <div className='market-search'>
                                    <h4 className='title-inventory'>Player</h4>
                                    <SearchSuggestions
                                        query={playerNames}
                                        searchTerm={searchPlayer}
                                        setSearchTerm={setSearchPlayer}
                                        suggestionsLength={10}
                                        zIndex={3}
                                    />
                                </div>
                                <div className='market-search'>
                                    <h4 className='title-inventory'>Team</h4>
                                    <SearchSuggestions
                                        query={teamNames}
                                        searchTerm={searchTeam}
                                        setSearchTerm={setSearchTeam}
                                        suggestionsLength={10}
                                        zIndex={1}
                                    />
                                </div>
                                <div className='market-search'>
                                    <h4 className='title-inventory'>Rarity</h4>
                                    <Checkboxes />
                                </div>
                                <div className='market-search'>
                                    <h4 className='title-inventory'>Boosts</h4>
                                    <div style={{ marginTop: "5px" }}>
                                        <h6 className='title-inventory'>Boost 1:</h6>
                                        <div className='market-boost-search-container'>
                                            <div className='market-boost-search'>
                                                <Dropdown
                                                    placeholder={"Boost"}
                                                    query={boostNames}
                                                    onSelect={(item) => handleBoostSelect(item, 0)}
                                                    selectedItem={selectedBoosts[0]}
                                                />
                                            </div>
                                            <div className='market-boost-tier-dropdown'>
                                                <Dropdown
                                                    placeholder={"Rarity"}
                                                    query={[{ name: "Bronze", tier: 1 }, { name: "Silver", tier: 2 }, { name: "Gold", tier: 3 }]}
                                                    onSelect={(item) => handleBoostTierSelect(item, 0)}
                                                    selectedItem={selectedBoostTiers[0]}
                                                />
                                            </div>
                                            <div className='market-boost-reset'>
                                                <button className='default-button' onClick={() => handleBoostFilterReset(0)}>Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <h6 className='title-inventory'>Boost 2:</h6>
                                        <div className='market-boost-search-container'>
                                            <div className='market-boost-search'>
                                                <Dropdown
                                                    placeholder={"Boost"}
                                                    query={boostNames}
                                                    onSelect={(item) => handleBoostSelect(item, 1)}
                                                    selectedItem={selectedBoosts[1]}
                                                />
                                            </div>
                                            <div className='market-boost-tier-dropdown'>
                                                <Dropdown
                                                    placeholder={"Rarity"}
                                                    query={[{ name: "none", name: "Bronze", tier: 1 }, { name: "Silver", tier: 2 }, { name: "Gold", tier: 3 }]}
                                                    onSelect={(item) => handleBoostTierSelect(item, 1)}
                                                    selectedItem={selectedBoostTiers[1]}
                                                />
                                            </div>
                                            <div className='market-boost-reset'>
                                                <button className='default-button' onClick={() => handleBoostFilterReset(1)}>Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <h6 className='title-inventory'>Boost 3:</h6>
                                        <div className='market-boost-search-container'>
                                            <div className='market-boost-search'>
                                                <Dropdown
                                                    placeholder={"Boost"}
                                                    query={boostNames}
                                                    onSelect={(item) => handleBoostSelect(item, 2)}
                                                    selectedItem={selectedBoosts[2]}
                                                />
                                            </div>
                                            <div className='market-boost-tier-dropdown'>
                                                <Dropdown
                                                    placeholder={"Rarity"}
                                                    query={[{ name: "none", name: "Bronze", tier: 1 }, { name: "Silver", tier: 2 }, { name: "Gold", tier: 3 }]}
                                                    onSelect={(item) => handleBoostTierSelect(item, 2)}
                                                    selectedItem={selectedBoostTiers[2]}
                                                />
                                            </div>
                                            <div className='market-boost-reset'>
                                                <button className='default-button' onClick={() => handleBoostFilterReset(2)}>Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className='default-button market-filter-button' onClick={submitFilters}>Search</button>
                            </div>
                        }
                        <div className='market-content-container' style={view === 'listings' ? { width: "100%" } : {}}>
                            <div className="market-content">
                                {currentEntries.length > 0 && (
                                    currentEntries.map((listing, index) => {
                                        // Check if the Card component is already cached
                                        const cacheKey = `${listing._id}-${index}-${currentUserId}-${onListingClick}`;
                                        if (!listingCache.current.has(cacheKey)) {
                                            listingCache.current.set(cacheKey, (
                                                <div key={cacheKey} className='col-3'>
                                                    <div className={`cardDisplay-container market-listing ${listing.owner._id === currentUserId ? 'highlight' : ''}`} onClick={() => onListingClick(listing)}>
                                                        <p className={`market-playername`}>{listing.card.player.name}</p>
                                                        <Card cardID={listing.card._id} market />
                                                        <div className='market-listing-price'>
                                                            <CoinIcon width='20px' height='20px' />
                                                            <p>{listing.price}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ));
                                        }
                                        // Return the cached Card component
                                        return listingCache.current.get(cacheKey);
                                    })
                                )}
                                {sellingCard && (
                                    <div className='market-sell-container'>
                                        <div className='market-sellbar-container'>
                                            <CoinIcon width='2vh' height='2vh' />
                                            <input
                                                className='market-sellbar'
                                                type='number'
                                                placeholder='Price'
                                                min='1'
                                                max='1000000'
                                                onChange={(e) => {
                                                    if (e.target.value !== '') {
                                                        // Remove any decimal points
                                                        const value = Math.max(1, Math.min(1000000, Math.floor(Number(e.target.value))));
                                                        e.target.value = value;
                                                        setSellPrice(value);
                                                    } else {
                                                        e.target.value = '';
                                                        setSellPrice(null);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <button className='default-button' onClick={handleCreateListing}>Sell</button>
                                    </div>
                                )}
                            </div>
                            <div className='pagination'>
                                <button className='default-button'
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                    disabled={currentPage === 1 || totalPages === 0}
                                >
                                    Previous
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button className='default-button'
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                    disabled={currentPage === totalPages || totalPages === 0}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                        {showMarketMenu && selectedListing && !sellingCard && (
                            <div className='popup-overlay'>
                                <MarketMenu
                                    listing={selectedListing}
                                    userID={currentUserId}
                                    onClose={() => { setShowMarketMenu(false); setSelectedListing(null); }}
                                    onUpdateListing={handleUpdateListing}
                                    onRemoveListing={handleRemoveListing}
                                />
                            </div>
                        )}
                    </div>
                )
            )}
        </div>
    );
};

export default Market;