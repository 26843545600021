import { useEffect, useState } from "react";
import { fetchWithAuth } from "./authHelpers";

export const useQuickSell = (cardID, coins, updateInventoryCache) => {
    const [bank, setBank] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBank = async () => {
            try {
                const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/banks/current`, 'GET');
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to fetch bank');
                }

                const data = await response.json();
                setBank(data);
            } catch (error) {
                console.error('Error fetching bank:', error);
            }
        };

        if (cardID) {
            fetchBank();
        }
    }, [cardID]);

    const fetchCardFantasyTeamStatus = async (cardID) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/cards/${cardID}/fantasyTeamStatus`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch card fantasy teams');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching card fantasy team status:', error);
        }
    };

    const removeCard = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/inventory/removeCard`, 'POST', { cardID });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to remove card');
            }

            const data = await response.json();
            return data;

        } catch (error) {
            console.error('Error removing card:', error);
        }
    };

    const deleteCard = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/cards/${cardID}`, 'DELETE');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to delete card');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error deleting card:', error);
        }
    };

    const addCoins = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/banks/current/addCoins`, 'PATCH', { coins: coins });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to add coins');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error adding coins:', error);
        }
    };

    const quickSell = async () => {
        if (bank) {
            const data = await fetchCardFantasyTeamStatus(cardID);
            const cardFantasyTeamStatus = data.status;
            if (!cardFantasyTeamStatus) {
                return false;
            }
            if (cardFantasyTeamStatus === 'ACTIVE') {
                setError(`Can't sell card in active fantasy team`);
                return false;
            }
            if (cardFantasyTeamStatus === 'INACTIVE') {
                await removeCard();
                await addCoins();
                if (updateInventoryCache) {
                    updateInventoryCache(cardID);
                }
                return true;
            }
            if (cardFantasyTeamStatus === 'NONE') {
                await removeCard();
                await deleteCard();
                await addCoins();
                if (updateInventoryCache) {
                    updateInventoryCache(cardID);
                }
                return true;
            }
        }
        return false;
    };

    return { quickSell, error, fetchCardFantasyTeamStatus };
};

export default useQuickSell;