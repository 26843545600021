import { useState } from 'react';
import { fetchWithAuth } from './authHelpers';

export const useLeaderboard = () => {
    const [leaderboard, setLeaderboard] = useState(null);
    const [error, setError] = useState(null);

    const fetchLeaderboardForEvent = async (event) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/events/${event.hltv_id}/fantasy-teams`, 'GET');

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch leaderboard');
            }

            const leaderboard = await response.json();

            // Combine points for users with multiple teams
            const userPoints = {};
            leaderboard.forEach((team) => {
                const userId = team.user._id;
                if (!userPoints[userId]) {
                    userPoints[userId] = { ...team.user, points: 0, boostPoints: 0, cards: [] };
                }
                userPoints[userId].points += team.points;
                userPoints[userId].boostPoints += team.boostPoints;

                userPoints[userId].cards.push(...team.cards.map(card => ({
                    card: card,
                    points: card.points,
                    lastPoints: card.lastPoints,
                    boostPoints: card.boostPoints,
                    lastBoostPoints: card.lastBoostPoints,
                })));
            });

            // Convert the accumulated points back into an array and sort it
            const combinedLeaderboard = Object.values(userPoints).sort((a, b) => b.points - a.points);

            setLeaderboard(combinedLeaderboard);
        } catch (err) {
            setError(err.message);
            console.error("Error fetching leaderboard:", err.message);
        }
    };

    const fetchLeaderboardForSeason = async (season) => {
        if (!season) {
            // Get the current season
            try {
                const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/seasons/current`, 'GET');

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to fetch current season');
                }

                const currentSeason = await response.json();
                season = currentSeason._id;
            } catch (err) {
                setError(err.message);
                console.error("Error fetching current season:", err.message);
                return;
            }
        }

        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/fantasyteam/season/${season}`, 'GET');

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch leaderboard');
            }

            const leaderboard = await response.json();
            // Combine points and count teams for users with multiple teams
            const userPoints = {};
            leaderboard.forEach((team) => {
                const userId = team.user._id;
                if (!userPoints[userId]) {
                    userPoints[userId] = { ...team.user, points: 0, teamCount: 0, teams: [] };
                }
                userPoints[userId].points += team.points;
                userPoints[userId].teamCount += 1;
                userPoints[userId].teams.push(team); // Correctly append the team to the teams array
            });


            // Convert the accumulated points back into an array and sort it
            const combinedLeaderboard = Object.values(userPoints).sort((a, b) => b.points - a.points);

            setLeaderboard(combinedLeaderboard);
        } catch (err) {
            setError(err.message);
            console.error("Error fetching leaderboard:", err.message);
        }
    };

    // USE THIS TO HIGHLIGHT THE CURRENT USER IN THE LEADERBOARD
    const getUserId = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/users/current`, 'GET');
            const data = await response.json();
            return data._id;
        } catch (error) {
            setError(error.message);
        }
    };

    return { leaderboard, error, fetchLeaderboardForEvent, fetchLeaderboardForSeason, getUserId };
};

export default useLeaderboard;